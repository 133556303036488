.iconItem {
  font-size: 30px;
  padding-top: 30%;
}

.iconList {
  max-width: fit-content;
  margin-left: 2%;
  list-style-type: none;
  margin-top: 5%;
  position: fixed;
}

/* @media (max-width: 767px) {
  .iconList {
    display: flex;
    position: relative;
  }
} */
.iconItem:hover {
  transform: translateY(-5px);
}
@media (max-width: 767px) {
  .iconItem {
    font-size: 21px; /* Reset to default value */
    padding-top: initial; /* Reset to default value */
  }

  .iconList {
    max-width: initial; /* Reset to default value */
    margin-left: initial; /* Reset to default value */
    margin-top: initial; /* Reset to default value */
    position: static; /* Reset to default value */
    display: block; /* Reset to default value */
  }
}
