.jobName {
  list-style-type: none;
  margin-bottom: 10%;
}

@media (max-width: 767px) {
  .container {
    flex-direction: column;
    padding: 10px;
  }
  .btn {
    margin-left: auto;
    margin-bottom: 8px;
    margin-right: auto;
    max-width: 200px;
  }
  .jobNameHeader {
    font-size: 21px;
  }
}

.jobButton {
  border: 1px solid white; /* Green */
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.jobButton:hover {
  background-color: rgb(135, 65, 216); /* Dark green */
  color: black;
  border: 1px solid black;
}

.container {
  display: flex;
  justify-content: center;
  margin-top: 3%;
  margin: 15px;
}

.jobButtonList {
  margin-right: 3%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.jobNameHeader {
  margin-bottom: 4%;
}
.jobDate {
  margin-bottom: 2%;
}
.jobList {
  margin: 0;
  padding: 0;
  line-height: 1.3;
  font-size: 18px;
  font-weight: 100;
}

.btn {
  position: relative;
  display: inline-block;
  padding: 6px 16px;
  font-size: 18px;
  text-decoration: none;
  color: rgb(135, 65, 216);
  background-color: transparent;
  border: 1px solid rgb(135, 65, 216);
  transition: 0.3s;
  overflow: hidden;
  cursor: pointer;
  border-radius: 10px;
}

.btn::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(135, 65, 216);
  bottom: -100%;
  left: 0;
  transition: 0.2s;
  z-index: -1;
}

.btn:hover::after {
  bottom: 0;
}

.btn:hover {
  color: #fff;
}

.btnFocused {
  padding: 6px 16px;
  font-size: 18px;
  border: 1px solid rgb(135, 65, 216);
  background-color: rgb(135, 65, 216);
  cursor: pointer;
  border-radius: 10px;
  color: #fff;
}
