.grid {
  display: flex;
  justify-content: center;
  margin-top: 3%;
  gap: 7%;
  flex: 1;
  flex-wrap: wrap;
}
.grid div {
  flex: 1;
}

.flexItemData {
  padding-left: 17%;
}

.flexItemImg {
  padding-right: 17%;
}
@media (max-width: 842px) {
  .grid {
    flex-direction: column;
    text-align: center;
  }
  .flexItemData {
    padding-left: initial;
    margin: 15px;
  }

  .flexItemImg {
    padding: 0;
    margin-bottom: 150px;
  }
}

.info {
  max-width: fit-content;
  margin: 0 auto;
  line-height: 1.3;
  font-size: 20px;
  padding-bottom: 15px;
  font-weight: 100;
}

.image {
  margin: 0 auto;
  display: block;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
}

.image:hover {
  transform: scale(1.1);
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.8);
}

.word {
  color: rgb(135, 65, 216);
}
