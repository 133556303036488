* {
  padding: 0;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  color: white;
}

body {
  background-color: #1d1d1d;
  font-family: 'Roboto', sans-serif;
}
.header {
  font-size: 50px;
  text-align: center;
  font-weight: 400;
  color: rgb(135, 65, 216);
}

.section {
  min-height: 90vh;
}
