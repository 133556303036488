.headers {
  margin-left: 20%;
  margin-right: auto;
  margin-top: 13%;
  font-size: 2.2rem;
  font-weight: 600;
}

.name {
  font-size: 1.2rem;
  text-align: left;
  margin-bottom: 2%;
  padding-left: 6px;
  color: rgb(135, 65, 216);
  font-weight: 100;
}

.titleSmall {
  line-height: 1.2;
}
.title {
  line-height: 1.2;
  width: fit-content;
}

.bounceContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bounceLetter {
  transition: transform 0.4s;
}

.bounceLetter:hover {
  animation: bounce 0.2s ease;
  color: rgb(192 132 252);
}

@keyframes bounce {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.space {
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 757px) {
  .headers {
    font-size: 1.2rem;
    text-align: center;
    margin: 0;
  }
  .name {
    margin-top: 150px;
    text-align: center;
  }
  .title {
    width: auto;
  }
  .bounceContainer {
    display: block;
  }
  .space {
    margin-right: 10px;
    display: inline-block;
  }
}
