.contactDescription {
  text-align: center;
  margin: 3%;
  font-size: 20px;
  font-weight: 100;
  width: 56%;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.3;
}

.container {
  text-align: center;
}

.btn {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  font-size: 20px;
  font-family: 'montserrat';
  text-decoration: none;
  color: rgb(135, 65, 216);
  background-color: transparent;
  border: 1px solid rgb(135, 65, 216);
  transition: 0.8s;
  overflow: hidden;
  z-index: 1;
}

.btn::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(135, 65, 216);
  bottom: -100%;
  left: 0;
  transition: 0.8s;
  z-index: -1;
}

.btn:hover::after {
  bottom: 0;
}

.btn:hover {
  color: #fff;
}

.footer {
  text-align: center;
}

.headerContact {
  font-size: 50px;
  text-align: center;
  font-weight: 400;
  color: rgb(135, 65, 216);
  margin-bottom: 5%;
}

.sectionContact {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  min-height: 90vh;
}
