.header {
  display: flex;
  justify-content: space-between;
  background-color: rgb(15, 0, 31);
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3); /* Add box-shadow for a three-dimensional effect */
  background-color: #181818;
  z-index: 1;
}

.logo {
  margin: 15px;
  color: white;
  background-color: rgb(15, 0, 31);
  background-color: #181818;
  cursor: pointer;
}

.navbar {
  text-align: right;
  margin: 15px;
  background-color: rgb(15, 0, 31);
  background-color: #181818;
}

.anchor {
  padding: 15px;
  text-decoration: none;
  color: white;
  font-size: 15px;
  background-color: rgb(15, 0, 31);
  background-color: #181818;
  font-weight: 300;
}
.anchor:hover {
  color: rgb(135, 65, 216);
}

.nav__toggler {
  display: block;
  cursor: pointer;
}
.navClickMenu {
  position: fixed;
  display: flex;
  width: 50vw;
  flex-direction: column;
  transition: 0.5s ease-in;

  text-align: center;
  justify-content: center;
  gap: 15px;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: #27272a;
}
.navClickMenu .anchor {
  background-color: transparent;
}
